@font-face {
  font-family: 'Basetica-Regular';
  src: url('Basetica.woff2') format('woff2'), url('Basetica.woff') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'Drone-Ranger-Pro-Bold';
  src: url('Drone-Ranger-Pro-Bold.woff2') format('woff2'), url('Drone-Ranger-Pro-Bold.woff') format('woff');
  font-display: swap;
}
